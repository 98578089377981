<template>
    <v-container>
        <v-form v-model="isValid" ref="jobForm" v-resize="onResize">
            <v-card class="card-job my-10 px-md-10 px-0 text-left" ref="cardCustomerInfo" id="card-customer-info" dark
                elevation="1">
                <v-card-title class="yellow--text text--darken-3">
                    <div class="d-flex justify-space-between" style="width: 100%">
                        <div>
                            <label>
                                รายการจัดส่ง
                            </label>
                        </div>
                        <div>
                            <v-btn rounded outlined color="yellow darken-3" @click="clearData">
                                <v-icon>
                                    mdi-trash-can-outline
                                </v-icon>
                                ลบข้อมูลทั้งหมด
                            </v-btn>
                        </div>
                    </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <label class="d-block white--text text-body-1 font-weight-bold">ข้อมูลส่วนตัว</label>
                    <label class="d-block grey--text text-body-1">ประเภท : {{ form.customer.type == 'person' ?
                        'บุคคลธรรมดา' : 'นิติบุคคล' }}</label>

                    <p class="text-caption mt-1">ชื่อลูกค้า</p>
                    <v-text-field color="yellow darken-3" required v-model="form.customer.name" dense :rules="nameRules"
                        placeholder="ex. ณัฐณีชา เบี้ยงศิริ">
                    </v-text-field>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <p class="text-caption mt-1">เบอร์โทรศัพท์</p>
                            <v-text-field color="yellow darken-3" required v-model="form.customer.mobile" dense
                                :rules="mobileRules" placeholder="ex. 0394992346, 021123211">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <p class="text-caption mt-1">อีเมล</p>
                            <v-text-field color="yellow darken-3" required v-model="form.customer.email" dense
                                :rules="emailRules" placeholder="ex. movevai@gmail.com">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <p class="text-caption mt-1">หมายเลขบัตรประชาชน / เลขที่ผู้เสียภาษี</p>
                    <v-text-field cy-data="idTax" color="yellow darken-3" required v-model="form.customer.idTax" dense maxlength="13"
                        :rules="taxRules">
                    </v-text-field>
                    <p class="text-caption mt-2">ที่อยู่</p>
                    <v-textarea color="yellow darken-3" filled outlined counter="255" v-model="form.customer.address"
                        rows="3" :rules="addressRules"></v-textarea>
                </v-card-text>
            </v-card>

            <v-card id="card-product-channel" class="my-5 px-md-10 px-0 py-3 card-job text-left" dark elevation="1">
                <v-card-text>
                    <label class="d-block customer-information text-body-1 font-weight-bold mb-5">เลือกบริการ</label>
                    <v-row>
                        <v-col cols="12" md="4" v-for="option of permissionOptionsWithCondition" :key="option.value"
                            @click="form.channel = option.value">
                            <div class="d-block">
                                <input type="radio" name="permissionOptions" v-model="form.channel"
                                    :value="option.value" :checked="form.channel == option.value">
                                <label class="text-body-1 ml-5">{{ option.label }}</label>
                            </div>
                            <div class="d-block" style="padding-left: 36px;">
                                <label
                                    class="d-block text-caption text-decoration-underline">เงื่อนไขการจัดส่งสินค้า</label>
                                <label class="d-block text-caption" v-for="(text, index) of option.policyText"
                                    :key="index">{{ text }}</label>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- Begin Ordering -->
            <div ref="cardProductInfo">
                <v-card v-for="(product, n) of form.products" :key="product.id" id="card-product-info"
                    class="my-5 px-md-10 px-0 card-job text-left" dark elevation="1">
                    <v-card-text>
                        <label class="d-block customer-information text-body-1 font-weight-bold">ข้อมูลสินค้า {{ n + 1
                            }} </label>
                        <v-radio-group :column="windowSize.x < 750" v-model="product.type">
                            <v-radio v-for="c of productTypeOptions" :key="c.value" class="mr-3" color="yellow darken-3"
                                :label="c.name" :value="c.value">
                            </v-radio>
                        </v-radio-group>
                        <div v-if="getSubProductType(product).length > 0">
                            <p class="text-caption mt-1">ประเภทสินค้า</p>
                            <v-select style="width: 30%" :items="getSubProductType(product)" item-text="name"
                                                            item-value="value" filled v-model="product.subType" dense></v-select>
                        </div>
                        <p class="text-caption mt-3">รายละเอียดสินค้า</p>
                        <v-text-field class="mt-n5" color="yellow darken-3" required v-model="product.details"
                            cy-data="productDetails"
                            placeholder="">
                        </v-text-field>
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="d-flex">
                                    <p class="text-caption mt-3" style="width: 65%">น้ำหนักสินค้า</p>
                                    <p v-if="product.type == '4'" class="text-caption mt-3" style="width: 35%">หน่วย</p>
                                </div>

                                <div class="d-flex mt-n5">
                                    <v-text-field style="width: 65%" color="yellow darken-3" required cy-data="productWeight"
                                        v-model="product.weight.value" :rules="numberAndDecimalRules" placeholder="">
                                    </v-text-field>
                                    <v-text-field v-if="product.type == '4'" style="width: 35%" color="yellow darken-3"
                                        required v-model="product.weight.unit"
                                        :rules="product.type == '4' ? requiredRules : []" placeholder="กรอกหน่วย">
                                    </v-text-field>
                                    <v-select v-else style="width: 35%" :items="getWeightUnit(product)" item-text="name"
                                        item-value="value" color="yellow darken-3" v-model="product.weight.unit">
                                    </v-select>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4" v-if="product.type != '1'">
                                <p class="text-caption mt-3">จำนวนสินค้า</p>
                                <div class="d-flex mt-n5">
                                    <v-text-field style="width: 70%" color="yellow darken-3" required cy-data="productQuantity"
                                        v-model="product.quantity.value" :rules="numberRules" placeholder="">
                                    </v-text-field>
                                    <v-select style="width: 30%;" :items="getQuantityUnit(product)" item-text="name"
                                        item-value="value" color="yellow darken-3" v-model="product.quantity.unit">
                                    </v-select>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4">
                                <p class="text-caption mt-3">มูลค่าสินค้า</p>
                                <div class="d-flex mt-n5">
                                    <v-text-field style="width: 70%" color="yellow darken-3" required cy-data="productPrice"
                                        v-model="product.price" :rules="numberWithCommasRules" placeholder="">
                                        <template v-slot:append>
                                            <label class="grey--text">| บาท</label>
                                        </template>
                                    </v-text-field>
                                </div>
                            </v-col>
                        </v-row>
                        <p class="text-caption mt-3">หมายเหตุ</p>
                        <v-text-field class="mt-n5" color="yellow darken-3" required v-model="product.note" cy-data="productNote"
                            placeholder="">
                        </v-text-field>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            v-if="product.id == form.products[form.products.length - 1].id && form.products.length > 1"
                            @click="removeListProducts" class="mr-6 mt-3 px-3" rounded color="grey darken-3">
                            <v-icon class="pt-1 pr-2" size="16">
                                mdi-minus
                            </v-icon>
                            ลบรายการ
                        </v-btn>
                        <v-btn v-if="product.id == form.products[form.products.length - 1].id" @click="addListProducts"
                            class="mr-6 mt-3 px-3" rounded color="yellow darken-3">
                            <v-icon class="pt-1 pr-2" size="16" color="black">
                                mdi-plus
                            </v-icon>
                            <span class="black--text">
                                เพิ่มรายการ
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <!-- End Ordering -->

            <v-card id="card-product-ordering" ref="cardProductOrdering" class="my-5 px-md-10 px-0 card-job text-left"
                dark elevation="1">
                <v-card-text>
                    <label
                        class="d-block customer-information text-body-1 font-weight-bold mb-6">ข้อมูลการจัดส่ง</label>
                    <p class="text-caption mt-1">น้ำหนักรวมสินค้า</p>
                    <v-row class="px-3">
                        <v-text-field color="yellow darken-3" required v-model="form.ordering.weight.value" cy-data="sumWeight"
                            :rules="numberAndDecimalRules" placeholder="">
                        </v-text-field>
                        <div style="width: 100px">
                            <v-select class="" :items="[{ name:'กิโลกรัม', value: 'kg'}, { name: 'กรัม', value: 'g'}]"
                                item-text="name" item-value="value" color="yellow darken-3"
                                v-model="form.ordering.weight.unit">
                            </v-select>
                        </div>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="12" md="4">
                            <p class="text-caption mt-1">ขนาดพัสดุ (กว้าง)</p>
                            <v-text-field 
                                color="yellow darken-3"
                                required 
                                v-model="form.ordering.width" 
                                :rules="numberRules"
                                placeholder="">
                                <template v-slot:append>
                                    <label class="grey--text">| cm</label>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <p class="text-caption mt-1">ขนาดพัสดุ (ยาว)</p>
                            <v-text-field 
                                color="yellow darken-3"
                                required 
                                v-model="form.ordering.length" 
                                :rules="numberRules"
                                placeholder="">
                                <template v-slot:append>
                                    <label class="grey--text">| cm</label>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <p class="text-caption mt-1">ขนาดพัสดุ (สูง)</p>
                            <v-text-field 
                                color="yellow darken-3"
                                required 
                                v-model="form.ordering.height" 
                                :rules="numberRules"
                                placeholder="">
                                <template v-slot:append>
                                    <label class="grey--text">| cm</label>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row> -->
                    <label
                        class="d-block customer-information text-body-1 font-weight-bold my-6">รูปแบบการจัดส่ง</label>
                    <v-radio-group class="mb-5" row v-model="form.orderType">
                        <v-radio color="yellow darken-3" label="รับส่ง" value="1"></v-radio>
                        <v-radio v-if="form.channel != '3'" color="yellow darken-3" label="ไป - กลับ" value="2">
                        </v-radio>
                    </v-radio-group>
                    <div v-if="form.orderType == '1'">
                        <p class="text-caption mt-1">สถานที่ในการจัดส่ง</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required readonly cy-data="address1Start"
                                    v-model="form.ordering.address1Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '1' ? mapRulesV2(form.ordering.address1Start.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Start, form.channel)"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn icon class="ml-n3" @click="swapAddress1">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required cy-data="address1Destination"
                                    v-model="form.ordering.address1Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '1' ? mapRulesV2(form.ordering.address1Destination.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Destination, form.channel)"
                                    placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                        </v-row>
                    </div>

                    <div v-else>
                        <p class="text-caption mt-1">สถานที่ในการจัดส่ง (ขาไป)</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required cy-data="address1Start"
                                    v-model="form.ordering.address1Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address1Start.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Start, form.channel)"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn icon class="ml-n3" @click="swapAddress1">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required cy-data="address1Destination"
                                    v-model="form.ordering.address1Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address1Destination.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Destination, form.channel)"
                                    placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                        </v-row>
                        <v-btn outlined color="yellow darken-3" @click="swapAddress">
                            สลับ
                            <v-icon>
                                mdi-swap-vertical
                            </v-icon>
                        </v-btn>
                        <p class="text-caption mt-5">สถานที่ในการจัดส่ง (ขากลับ)</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required cy-data="address2Start"
                                    v-model="form.ordering.address2Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address2Start.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address2Start, form.channel)"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn icon class="ml-n3" @click="swapAddress2">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required cy-data="address2Destination"
                                    v-model="form.ordering.address2Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address2Destination.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address2Destination, form.channel)"
                                    placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-divider />
                <div class="d-flex justify-space-between px-5 mt-8" style="width: 100%;">
                    <div>
                        <label class="font-weight-bold">
                            อัตราค่าจัดส่งรวมประกันสินค้า
                        </label>
                    </div>
                    <div class="mr-3">
                        <label class="font-weight-bold">
                            {{ shippingPrice }} บาท
                        </label>
                    </div>
                </div>
            </v-card>
            <div class="d-flex justify-space-between my-8" style="width: 100%;">
                <div>
                    <v-btn outlined rounded color="yellow darken-3" width="150" @click="$refs.confirm.show()">
                        ยกเลิกรายการ
                    </v-btn>
                </div>
                <div class="text-right">
                    <v-btn :class="{ 'mr-3': windowSize.x > 485 }" outlined rounded color="yellow darken-3" width="150"
                        @click="saveDraft">
                        บันทึกแบบร่าง
                    </v-btn>
                    <v-btn :class="{ 'mt-3': windowSize.x < 473 }" rounded color="yellow darken-3" width="150"
                        @click="createJob">
                        ดำเนินการ
                    </v-btn>
                </div>
            </div>
        </v-form>
        <dialog-address-form @confirm="dialogAddressConfirm" ref="dialogAddressForm" />
        <dialog-confirm :title="'ยืนยันออกจากหน้าสร้างรายการสั่งซื้อ'"
            :description="'โปรดตรวจสอบทุกครั้งว่าได้บันทึกข้อมูลแล้วหรือยัง เพื่อป้องกันไม่ไห้ข้อมูลหายไปหลังจากกดออก'"
            @confirm="cancel" ref="confirm" />
        <dialog-error ref="dialogError" :topic="'Error'" :text="errorMessage" />
        <dialog-success ref="dialogSuccess" :text="'บันทึกเรียบร้อยแล้ว'" :redirectTo="null" />
        <dialog-success ref="dialogCreateSuccess" :topic="'ส่งข้อมูลให้บริษัทเรียบร้อย'"
            :text="'ทางบริษัทจะคำนวณราคาค่าจัดส่งและจะแจ้งกลับหาคุณโดยเร็วที่สุด หากมีข้อสงสัยติดต่อ 02-613-4782'"
            :icon="'mdi-send-circle-outline'" :autoClose="false" @hide="$router.push({ name: 'Home' })" />
    </v-container>
</template>

<script>
import * as Job from '../models/job';
import * as Customer from '../models/customer';
import { nameRules, mobileRules, emailRules, addressRules, numberRules, requiredRules, mapRules, mapRulesV2, numberWithCommasRules, numberAndDecimalRules, taxRules } from '../utils/validation-rule';
import * as Text from '../utils/text-mapping';
import dialogAddressForm from '../components/DialogAddressForm.vue';
import DialogConfirm from '../components/DialogConfirm.vue';
import DialogError from '../components/DialogError.vue';
import DialogSuccess from '../components/DialogSuccess.vue';

export default {
    data: () => ({
        nameRules,
        mobileRules,
        emailRules,
        addressRules,
        numberRules,
        requiredRules,
        mapRules,
        numberWithCommasRules,
        numberAndDecimalRules,
        mapRulesV2,
        taxRules,
        isValid: false,
        permissionOptions: Text.permissionOptions(),
        productTypeOptions: [],
        channelOptions: Job.channelOptions,
        form: {},
        errorMessage: '',
        shippingPrice: 0,
        lastAction: 0,
        windowSize: { x: 0, y: 0 },
        customer: null,
        customerAddress: ''
    }),
    methods: {
        addListProducts(){
            this.form.products.push( Job.mockProduct() );
        },
        getSubProductType(product){
            let result = [];
            if (product && this.form.channel) {
                let options = this.channelOptions[this.form.channel];
                if (options) {
                    result = options.subProductType[product.type];
                }
            }
            return result;
            // return product ? this.channelOptions[this.form.channel].subProductType[product.type] : [];
        },
        getWeightUnit(product){
            return product ? this.channelOptions[this.form.channel].weightUnit[product.type] : []; 
        },
        getQuantityUnit(product){
            return product ? this.channelOptions[this.form.channel].quantityUnit[product.type] : []; 
        },
        removeListProducts(){
            this.form.products.pop();
        },
        setAddressId() {
            this.form.ordering.address1Start.id = 'address1Start';
            this.form.ordering.address1Destination.id = 'address1Destination';
            this.form.ordering.address2Start.id = 'address2Start';
            this.form.ordering.address2Destination.id = 'address2Destination';
        },
        swapAddress1(){
            const temp = this.form.ordering.address1Start;
            this.form.ordering.address1Start = this.form.ordering.address1Destination;
            this.form.ordering.address1Destination = temp;
            this.setAddressId();
        },
        swapAddress2(){
            const temp = this.form.ordering.address2Start;
            this.form.ordering.address2Start = this.form.ordering.address2Destination;
            this.form.ordering.address2Destination = temp;
            this.setAddressId();
        },
        swapAddress(){
            const temp1 = this.form.ordering.address1Start;
            const temp2 = this.form.ordering.address1Destination;
            this.form.ordering.address1Start = this.form.ordering.address2Start;
            this.form.ordering.address1Destination = this.form.ordering.address2Destination;
            this.form.ordering.address2Start = temp1;
            this.form.ordering.address2Destination = temp2;
            this.setAddressId();
        },
        dialogAddressConfirm(data){
            this.form.ordering[data.id] = data;
        },
        async cancel () {
            await this.clearData();
            await this.saveDraft();
            this.$router.push({ name: 'Home' });
        },
        async saveDraft(){
            let loader = this.$loading.show();
            this.errorMessage = '';
            try{
                await Job.saveDraftApi(this.form);
                const data = await Job.getDraftApi();
                if(data){
                    this.form = data;
                }
                this.paymentFileName = '';
                this.$refs.dialogSuccess.show();
            }catch(error){
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.dialogError.show();
            }finally{
                loader.hide();
            }
        },
        async createJob(){
            this.$refs.jobForm.validate();
            this.errorMessage = '';

            if(!this.isValid){
                this.errorMessage = 'โปรดกรอกข้อมูลให้ถูกต้อง';
                return this.$refs.dialogError.show();
            }else{
                const loader = this.$loading.show();
                try{
                    await Job.createJobApi(this.form);
                    this.$refs.dialogCreateSuccess.show();
                }catch(error){
                    this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                    this.$refs.dialogError.show();
                }finally{
                    loader.hide();
                }
            }
        },
        async calculateShippingPrice() {
            // protect bounce issues. https://stackoverflow.com/questions/28609994/javascript-prevent-function-from-executing-multiple-times
            if (this.lastAction >= (Date.now() - 1000)) return;
            this.lastAction = Date.now();

            if(this.form.ordering.weight && this.form.ordering.address1Start && this.form.ordering.address1Destination){
                this.shippingPrice = 'กำลังคำนวณ..';
                try{
                    const response = await Job.calculateShippingPrice(this.form);
                    this.shippingPrice = response.data.price;
                    this.form.ordering.shippingFee = this.shippingPrice;
                }catch(error){
                    console.log(error);
                    this.shippingPrice = '-';
                }
            }
        },
        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
        async clearData () {
            Object.assign(this.form, Job.initForm());
            await this.setCustomerForm();
            this.$refs.jobForm.resetValidation();
        },
        autoFillAddress1Start(){
            // Auto fill when channel is move sameday and move express
            if('1' == this.form.channel || '3' == this.form.channel){
                this.form.ordering.address1Start.address1 = 'แขวงสีลม, เขตบางรัก, จังหวัดกรุงเทพมหานคร, 10500';
                this.form.ordering.address1Start.address2 = 'บริษัท มูฟไว จำกัด 32/7-8 ถ.สุรศักดิ์';
                this.form.ordering.address1Start.text = `${this.form.ordering.address1Start.address2} ${this.form.ordering.address1Start.address1}`;
                this.form.ordering.address1Start.location = { lat: 13.7211614, lng: 100.5191163 };
            }else{
                this.form.ordering.address1Start.address1 = '';
                this.form.ordering.address1Start.address2 = '';
                this.form.ordering.address1Start.text = '';
                this.form.ordering.address1Start.location = null;
            }
        },
        async setCustomerForm () {
            let loader = this.$loading.show();
            try {
                const response = await Customer.getMyProfile();
                this.customer = response.data;
                this.form.customer = this.customer;
                this.customerAddress = this.customer.address.address2 + ' ' + this.customer.address.address1;
                this.form.customer.address = this.customerAddress;
            } catch (error) {
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.dialogError.show();
            } finally {
                loader.hide();
            }
        }
    },
    computed: {
        permissionOptionsWithCondition(){
            // V.1 filter move transit out when user role is corp.
            // if('person' == this.form.customer.type){
            //     return this.permissionOptions.filter(p => p.value != '2');
            // }else{
            //     return this.permissionOptions;
            // }

            // v.2 allow all
            return this.permissionOptions;
        }
    },
    async mounted(){
        let loader = this.$loading.show();
        try{
            const data = await Job.getDraftApi();
            if(data){
                this.form = data;
            }else{ // set defalut customer data
                const response = await Customer.getMyProfile();
                this.customer = response.data;
                this.form.customer = this.customer;
                this.customerAddress = this.customer.address.address2 + ' ' + this.customer.address.address1;
                this.form.customer.address = this.customerAddress;
            }
        }catch(error){
            this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
            this.$refs.dialogError.show();
        }finally{
            this.autoFillAddress1Start();
            loader.hide();
        }
    },
    created(){
        this.form = Job.initForm();
        this.productTypeOptions = this.channelOptions[this.form.channel].productType;
    },
    watch: {
        'form.ordering.weight' : {
            handler(){
                this.calculateShippingPrice();
            },
            deep: true
        },
        'form.ordering.address1Start.location'(){
            this.calculateShippingPrice();
        },
        'form.ordering.address1Destination.location'(){
            this.calculateShippingPrice();
        },
        'form.channel'(value){
            this.productTypeOptions = this.channelOptions[value].productType;
            this.form.products.forEach(p => { // fix. when choose product type with no render will error
                if ('3' == this.form.channel && '2' == p.type) {
                    // because of channel 3 don't have product type 2 then fix to type 3
                    p.type = '3';
                }
            });
            // protect null value
            if('3' == this.form.channel){
                this.form.orderType = this.form.orderType || '1';
                this.form.paymentType = this.form.paymentType || '1';
            }

            this.autoFillAddress1Start();

            // clear all address when channel is changed.
            this.form.orderType = '1';
            console.log(this.form.orderType);

            this.form.ordering.address1Destination.address1 = '';
            this.form.ordering.address1Destination.address2 = '';
            this.form.ordering.address1Destination.text = '';
            this.form.ordering.address1Destination.location = null;

            this.form.ordering.address2Start.address1 = '';
            this.form.ordering.address2Start.address2 = '';
            this.form.ordering.address2Start.text = '';
            this.form.ordering.address2Start.location = null;

            this.form.ordering.address2Destination.address1 = '';
            this.form.ordering.address2Destination.address2 = '';
            this.form.ordering.address2Destination.text = '';
            this.form.ordering.address2Destination.location = null;
        },
        'form.products': {
            // fix. value in scope
            handler(){
                for(let i = 0; i < this.form.products.length ; i++){
                    const type = this.form.products[i].type;
                    const weightUnit = this.form.products[i].weight.unit;
                    const quantityUnit = this.form.products[i].quantity.unit;
                    switch(type) {
                        case '1':
                            if('b' != weightUnit){
                                this.form.products[i].weight.unit = 'b';
                            }
                            break;
                        case '2':
                            if(-1 == ['b', 'g', 'kg'].indexOf(weightUnit)){
                                this.form.products[i].weight.unit = 'b';
                            }
                            if('1' != quantityUnit){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '3':
                            if(-1 == ['b', 'g', 'kg'].indexOf(weightUnit)){
                                this.form.products[i].weight.unit = 'b';
                            }
                            if(-1 == ['1', '2', '3', '4'].indexOf(quantityUnit)){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '4':
                            if(-1 == ['1', '2', '3', '4'].indexOf(quantityUnit)){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '5':
                            if('g' != weightUnit){
                                this.form.products[i].weight.unit = 'g';
                            }
                            if('1' != quantityUnit){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '6':
                            if('g' != weightUnit){
                                this.form.products[i].weight.unit = 'g';
                            }
                            if('1' != quantityUnit){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        default:
                            break;
                    }

                    // replace price with comma.
                    if(this.form.products[i].price){
                        const price = this.form.products[i].price.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        // this.form.products[i].price = price;
                        this.$nextTick(() => this.form.products[i].price = price);
                    }
                }
            },
            deep: true
        }
    },
    components: {
        dialogAddressForm,
        DialogConfirm,
        DialogError,
        DialogSuccess
    }
};
</script>

<style scoped>
    .card-job {
        background-color: #0F0E0D;
        border-radius: 8px;
        padding-bottom: 32px;
    }
    input[type="radio"] {
        border:2px solid #000;
        box-shadow:0 0 0 1px #999999;
        appearance:none;
        border-radius:50%;
        background-color:#000;
        transition:all ease-in 0.2s;
        height: 16px;
        width: 16px;
    }
    input[type="radio"]:checked {
        box-shadow: 0 0 0 2px #C49C4F;
        color: #C49C4F;
        background-color: #C49C4F;
    }
</style>

<style>
    .v-select__selections{
        line-height: 25px !important;
        margin-top: -7px;
    }
    
</style>