<template>
    <div class="mt-n6 div-order-home">
        <div class="pt-10 d-block">
            <label
                class="yellow--text text--darken-3 font-weight-bold text-h6 pb-2"
                style="border-bottom: 1px solid;"
            >
                สร้างรายการ
            </label>
        </div>
        <div v-if="isHide">
            <job-form>

            </job-form>
        </div>
        <pdpa ref="dialogPDPA" @hide="hidePDPA"/>
    </div>
</template>

<script>
import JobForm from '../../components/JobForm.vue';
import DialogPDPA from '../../components/DialogPDPA.vue';

export default {
    data: () => ({
        errorMessage: '',
        isHide: false
    }),
    methods: {
        hidePDPA(){
            this.isHide = true;
        }
    },
    components: {
        JobForm,
        'pdpa': DialogPDPA
    },
    mounted(){
        // this.$vuetify.theme.dark = true;
        this.$refs.dialogPDPA.show();
    }
};
</script>

<style scoped>
    .div-order-home{
        text-align: center;
        min-height: 500px;
    }
</style>