<template>
    <v-dialog v-model="dialog" :width="windowSize.x > 900 ? '60%' : '80%'" overlay-opacity="0.8" persistent
        v-resize="onResize">
        <v-card color="white" class="py-10 px-5" align="center">
            <label class="d-block text-left">
                บริษัท มูฟไว จำกัด (ต่อไปเรียกว่า “บริษัท”)
                เคารพสิทธิความเป็นส่วนตัวของผู้ใช้งานเว็บไซต์นักลงทุนสัมพันธ์ (ต่อไปเรียกว่า “ท่าน”)
                และเพื่อให้เกิดความมั่นใจว่าท่านได้รับความคุ้มครองเรื่องข้อมูลส่วนบุคคล
                บริษัทขอแจ้งให้ท่านทราบถึงรายละเอียดที่เกี่ยวข้องกับการรวบรวม บันทึก จัดเก็บ
                และการใช้ข้อมูลส่วนบุคคลของท่าน (ต่อไปเรียกว่า “เก็บรวบรวม”)
                รวมตลอดถึงการลบและทำลายข้อมูลส่วนบุคคลของท่าน ดังนี้
                วัตถุประสงค์การเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้งานเว็บไซต์
            </label>
            <ol class="text-left ml-0 ml-md-5">
                <li>
                    บริษัทจัดเก็บข้อมูลส่วนบุคคลของท่านเพื่อประโยชน์ในการจัดฐานข้อมูลในการวิเคราะห์
                    และเสนอสิทธิประโยชน์ตามความสนใจของท่าน
                </li>
                <li>
                    บริษัทจัดเก็บข้อมูลส่วนบุคคลของท่านเพื่อการดำเนินการชำระเงินค่าบริการหรือสินค้าที่ท่านซื้อในระบบ
                </li>
                <li>
                    บริษัทจัดเก็บข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ในการรับข้อมูลหลังการบริการ เช่น
                    การสอบถามข้อมูลการแสดงความคิดเห็นหลังการบริการ หรือ การส่งคำร้องแก่ผู้ให้บริการ เป็นต้น
                </li>
                <li>
                    บริษัทจัดเก็บข้อมูลส่วนบุคคลเพื่อยืนยันตัวตนว่าท่านเป็นผู้เดียวในการเข้าถึงบัญชีของท่าน
                </li>
                <li>
                    บริษัทจัดเก็บข้อมูลส่วนบุคคลของท่านเพื่อวิจัยการตลาดและบริหารความสัมพันธ์ระหว่างผู้ให้บริการและผู้ใช้บริการ
                </li>
                <li>
                    บริษัทจัดเก็บข้อมูลส่วนบุคคลของท่านเพื่อปฏิบัติตามข้อกฎหมาย และระเบียบบังคับใช้ของรัฐ
                </li>
                <li>
                    บริษัทจัดเก็บข้อมูลส่วนบุคคลของท่านเพื่อปฏิบัติตามกฎระเบียบที่ใช้บังคับกับผู้บริการ
                    รวมถึงการยินยอมให้ผู้ให้บริการสามารถโอนข้อมูลส่วนบุคคลให้แก่กลุ่มธุรกิจและพันธมิตรของผู้ให้บริการ
                    ผู้ประมวลผลข้อมูล หรือหน่วยงานใดๆที่มีสัญญากับผู้ให้บริการ
                </li>
            </ol>
            <label class="d-block text-left">
                ข้อมูลส่วนบุคลของท่านจะถูกเก็บรวบรวมในกรณีต่อไปนี้
            </label>
            <ol class="text-left ml-0 ml-md-5">
                <li>
                    เมื่อท่านเข้าชมเว็บไซต์นักลงทุนสัมพันธ์ของบริษัท
                    <ul>
                        <li>
                            ข้อมูลเกี่ยวกับอุปกรณ์อิเล็กทรอนิกส์ที่ท่านใช้ เช่น IP Address หรือ other device identifier
                        </li>
                        <li>
                            ชนิดและเวอร์ชันของบราวเซอร์ที่ท่านใช้
                        </li>
                        <li>
                            การตั้งค่าเขตเวลา (Time Zone)
                        </li>
                    </ul>
                </li>
                <li>
                    เมื่อท่านติดต่อบริษัท หรือสมัครสมาชิกรับข่าวสาร ข้อมูลส่วนบุคคลของท่านที่จะถูกเก็บรวบรวม ได้แก่
                    <ul>
                        <li>
                            ข้อมูลส่วนตัว เช่น ชื่อ อายุ สัญชาติ วันเกิด อายุ 10 ปี นับแต่วันที่เลิกสัญญา
                        </li>
                        <li>
                            ข้อมูลส่วนตัว อาทิ ชื่อบัญชีผู้ใช้ รหัสผ่าน การสั่งซื้อ
                            ความสนใจของท่านที่มีต่อบริการต่างๆของผู้ให้บริการ 5 ปี นับแต่วันที่เลิกสัญญา
                        </li>
                        <li>
                            ข้อมูลการติดต่อ เช่น เบอร์โทรศัพท์ อีเมล 10 ปี นับแต่วันที่เลิกสัญญา
                        </li>
                        <li>
                            ข้อมูลบัญชี อาทิ รายละเอียดการชำระเงิน และบัญชีธนาคาร 10 ปี นับแต่วันที่เลิกสัญญา
                        </li>
                        <li>
                            ข้อมูลทางธุรกรรม อาทิ ประวัติการรับบริการต่างๆ ประวัติการซื้อขาย10 ปี นับแต่วันที่เลิกสัญญา
                        </li>
                        <li>
                            ข้อมูลทางเทคนิค อาทิ Google Analytics หมายเลขระบุตำแหน่งคอมพิวเตอร์ (IP Address)
                            ข้อมูลการเข้าระบบ ข้อมูลการใช้งาน และ การตั้งค่า (log) 5 ปี นับแต่วันที่เลิกสัญญา
                        </li>
                        <li>
                            ข้อมูลทางการตลาด อาทิ ความพึงพอใจของท่านต่อบริการที่ได้รับ
                            และความเห็นต่อการให้บริการของบุคลากร5 ปี นับแต่วันที่เลิกสัญญา
                        </li>
                    </ul>
                </li>
                <li>
                    เมื่อท่านสมัครใจที่จะเปิดเผยข้อมูลดังกล่าว หรือข้อมูลดังกล่าวถูกส่งมาให้เราโดยบุคคลอื่น เช่น
                    ผู้ให้บริการเว็บไซต์ภายนอก โดยคำสั่งหรือการกระทำของท่าน
                    บริษัทมีความจำเป็นในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อใช้งานตามวัตถุประสงค์ที่เราแจ้งไว้ในนโยบายความเป็นส่วนตัวนี้
                </li>
            </ol>
            <label class="d-block text-left mt-3">
                &emsp;&emsp;&emsp;&emsp;&emsp;คุ้กกี้ และเทคโนโลยีอื่นๆ ที่ทำหน้าที่คล้ายคลึงกัน
                เว็บไซต์นักลงทุนสัมพันธ์ของบริษัทมีการใช้คุ้กกี้เพื่อเก็บรวบรวมข้อมูลส่วนบุคคล
                ตามที่กำหนดไว้ตามนโยบายการใช้คุ้กกี้
            </label>
            <label class="d-block text-left">
                &emsp;&emsp;&emsp;&emsp;&emsp;ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้งานเว็บไซต์
                บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นตามวัตถุประสงค์ที่แจ้งไว้ในนโยบายนี้
                และตามการแก้ไขเปลี่ยนแปลงนโยบายที่อาจมีในอนาคต หรือตามที่กฎหมายกำหนด เช่น กฎหมายภาษีอากร กฎหมายแรงงาน
                หรือจนกว่าท่านจะใช้สิทธิเพิกถอนความยินยอมที่ได้ให้ไว้แก่บริษัท
            </label>
            <label class="d-block text-left">
                &emsp;&emsp;&emsp;&emsp;&emsp;การลบและทำลายข้อมูลส่วนบุคคล
                เมื่อความจำเป็นในการเก็บรวบรวมข้อมูลส่วนบุคคลตามวัตถุประสงค์ข้างต้นหมดลง บริษัทจะลบ
                และทำลายข้อมูลส่วนบุคคลของท่านออกจากระบบและบันทึกต่าง ๆ เว้นแต่เพื่อปฏิบัติตามกฎหมาย รักษาสิทธิ
                ปกป้องสิทธิและต่อสู้ตามกฎหมาย
            </label>
            <label class="d-block text-left">
                &emsp;&emsp;&emsp;&emsp;&emsp;สิทธิของผู้ใช้งานเว็บไซต์
                บริษัทใช้ข้อมูลส่วนบุคคลของท่านโดยขอความยินยอมจากท่าน
                บริษัทจะเก็บรวบรวมข้อมูลดังกล่าวจนกว่าท่านจะแจ้งขอเพิกถอนความยินยอม เช่น
                ท่านต้องการยกเลิกการสมัครสมาชิกรับข่าวสาร ลบคุ้กกี้ ท่านสามารถแจ้งความประสงค์ของท่านให้บริษัททราบ
                บริษัทจะดำเนินการตามคำขอของท่าน
            </label>

            <v-btn class="mt-5 px-9" color="yellow darken-3" rounded @click="hide">
                ยอมรับ
            </v-btn>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
    props: {
    },
    data: () => ({
        dialog: false,
        windowSize: { x: 0, y: 0 }
    }),
    methods: {
        show(){
            this.dialog = true;
        },
        hide(){
            this.dialog = false;
            this.$emit('hide');
        },
        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        }
    },
    mounted(){

    }
};
</script>