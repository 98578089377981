var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-n6 div-my-order"},[_vm._m(0),_c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"pb-16",staticStyle:{"background-color":"#1F1C1B"},style:({width: _vm.windowSize.w + 'px'})},[_c('v-tabs',{staticClass:"mt-8 pb-16",attrs:{"vertical":"","dark":"","color":"yellow darken-3"}},[_c('v-tab',{staticClass:"mt-5",staticStyle:{"border-bottom":"0.5px solid"},on:{"click":function($event){_vm.tab = 1}}},[_c('span',{staticClass:"white--text"},[_vm._v("รายการจัดส่งปัจจุบัน")])]),_c('v-tab',{staticStyle:{"border-bottom":"0.5px solid"},on:{"click":function($event){_vm.tab = 2}}},[_c('span',{staticClass:"white--text"},[_vm._v("ประวัติรายการจัดส่ง")])]),_c('v-tabs-items',{attrs:{"dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":1}},[_c('v-card',{staticClass:"ml-2",staticStyle:{"background-color":"#0E0D0C"},attrs:{"dark":"","flat":""}},[_c('v-card-text',{staticClass:"text-left"},[_c('v-container',[_c('label',{staticClass:"text-h6 font-wegiht-bold yellow--text text--darken-3"},[_vm._v("รายการทั้งหมด ")]),_c('label',{staticClass:"font-wegiht-bold yellow--text text--darken-3"},[_vm._v("( "+_vm._s(_vm.jobsCurrent.length)+" รายการ)")]),_c('v-data-table',{staticClass:"grey--text",staticStyle:{"background-color":"#0E0D0C"},attrs:{"headers":_vm.headers,"items":_vm.jobsCurrent,"items-per-page":100,"hide-default-footer":"","no-data-text":"ไม่พบข้อมูล"},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticClass:"d-block"},[_c('v-icon',[_vm._v(" mdi-package-variant-closed ")]),_vm._v(" "+_vm._s(item.code)+" ")],1)])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._l((item.products),function(p,index){return _c('label',{key:index,staticClass:"d-block"},[_vm._v(" "+_vm._s(p.details ? p.details : _vm.transformProductType(p.type))+" ")])}),_c('label',{staticClass:"d-block"},[_vm._v(" น้ำหนัก "+_vm._s(_vm.transformWeight(item.ordering.sumWeight))+" ")]),_c('label',{staticClass:"d-block"},[_vm._v(" ขนาด "+_vm._s(_vm.transformSize(item.ordering))+" cm. ")])],2)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.transformDate(item.ordering.createdAt))+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticClass:"d-block",class:_vm.getColorClass(item).text},[_c('v-icon',{attrs:{"color":_vm.getColorClass(item).icon}},[_vm._v(" mdi-circle-medium ")]),_vm._v(" "+_vm._s(_vm.transformStatusText(item))+" ")],1)])]}}],null,true)})],1)],1)],1)],1),_c('v-tab-item',{attrs:{"value":2}},[_c('v-card',{staticClass:"ml-2",staticStyle:{"background-color":"#0E0D0C"},attrs:{"dark":"","flat":""}},[_c('v-card-text',[_c('v-container',{staticClass:"text-left"},[_c('label',{staticClass:"text-h6 font-wegiht-bold yellow--text text--darken-3"},[_vm._v("รายการทั้งหมด ")]),_c('label',{staticClass:"font-wegiht-bold yellow--text text--darken-3"},[_vm._v("( "+_vm._s(_vm.jobsSentCancel.length)+" รายการ)")]),_c('v-data-table',{staticClass:"grey--text",staticStyle:{"background-color":"#0E0D0C"},attrs:{"headers":_vm.headers,"items":_vm.jobsSentCancel,"items-per-page":100,"hide-default-footer":"","no-data-text":"ไม่พบข้อมูล"},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticClass:"d-block"},[_c('v-icon',[_vm._v(" mdi-package-variant-closed ")]),_vm._v(" "+_vm._s(item.code)+" ")],1)])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._l((item.products),function(p,index){return _c('label',{key:index,staticClass:"d-block"},[_vm._v(" "+_vm._s(p.details ? p.details : _vm.transformProductType(p.type))+" ")])}),_c('label',{staticClass:"d-block"},[_vm._v(" น้ำหนัก "+_vm._s(_vm.transformWeight(item.ordering.sumWeight))+" ")]),_c('label',{staticClass:"d-block"},[_vm._v(" ขนาด "+_vm._s(_vm.transformSize(item.ordering))+" cm. ")])],2)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.transformDate(item.ordering.createdAt))+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticClass:"d-block",class:_vm.getColorClass(item).text},[_c('v-icon',{attrs:{"color":_vm.getColorClass(item).icon}},[_vm._v(" mdi-circle-medium ")]),_vm._v(" "+_vm._s(_vm.transformStatusText(item))+" ")],1)])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1),_c('dialog-error',{ref:"dialogValidateFail",attrs:{"topic":'ข้อมูลไม่ถูกต้อง',"text":'กรุณากรอกข้อมูลใหม่อีกครั้ง'}}),_c('dialog-error',{ref:"dialogError",attrs:{"topic":'Error',"text":_vm.errorMessage}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-10 d-block"},[_c('label',{staticClass:"yellow--text text--darken-3 font-weight-bold text-h6 pb-2",staticStyle:{"border-bottom":"1px solid"}},[_vm._v(" รายการของฉัน ")])])}]

export { render, staticRenderFns }