<template>
    <div class="mt-n6 div-my-order">
        <div class="pt-10 d-block">
            <label
                class="yellow--text text--darken-3 font-weight-bold text-h6 pb-2"
                style="border-bottom: 1px solid;"
            >
                รายการของฉัน
            </label>
        </div>
        <v-container class="pb-16" v-resize="onResize" :style="{width: windowSize.w + 'px'}" style="background-color: #1F1C1B">
        <v-tabs vertical dark class="mt-8 pb-16" color="yellow darken-3">
            <v-tab class="mt-5" style="border-bottom: 0.5px solid;" @click="tab = 1">
                <span class="white--text">รายการจัดส่งปัจจุบัน</span>
            </v-tab>
            <v-tab style="border-bottom: 0.5px solid;" @click="tab = 2">
                <span class="white--text">ประวัติรายการจัดส่ง</span>
            </v-tab>

            <v-tabs-items v-model="tab" dark>
            <v-tab-item :value="1">
                <v-card  dark class="ml-2" flat style="background-color: #0E0D0C;">
                    <v-card-text class="text-left">
                        <v-container>
                            <label class="text-h6 font-wegiht-bold yellow--text text--darken-3">รายการทั้งหมด </label>
                            <label class="font-wegiht-bold yellow--text text--darken-3">( {{ jobsCurrent.length }} รายการ)</label>
                                <v-data-table
                                    class="grey--text"
                                    style="background-color: #0E0D0C;"
                                    :headers="headers"
                                    :items="jobsCurrent"
                                    :items-per-page="100"
                                    hide-default-footer
                                    no-data-text="ไม่พบข้อมูล"
                                    @click:row="clickRow"
                                >
                                    <template v-slot:[`item.code`]="{ item }">
                                        <div>
                                            <label class="d-block">
                                                <v-icon>
                                                    mdi-package-variant-closed
                                                </v-icon>
                                                {{ item.code }}
                                            </label>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.details`]="{ item }">
                                        <div>
                                            <label v-for="(p, index) of item.products" :key="index" class="d-block">
                                                {{ p.details ? p.details : transformProductType(p.type) }}
                                            </label>
                                            <label class="d-block">
                                                น้ำหนัก {{ transformWeight(item.ordering.sumWeight) }}
                                            </label>
                                            <label class="d-block">
                                                ขนาด {{ transformSize(item.ordering) }} cm.
                                            </label>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.createdAt`]="{ item }">
                                        <div>
                                            <label class="d-block">
                                                {{ transformDate(item.ordering.createdAt) }}
                                            </label>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <div>
                                            <label class="d-block" :class="getColorClass(item).text">
                                                <v-icon :color="getColorClass(item).icon">
                                                    mdi-circle-medium
                                                </v-icon>
                                                {{ transformStatusText(item) }}
                                            </label>
                                        </div>
                                    </template>
                                </v-data-table>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item :value="2">
                <v-card dark class="ml-2" flat style="background-color: #0E0D0C;">
                    <v-card-text>
                       <v-container class="text-left">
                            <label class="text-h6 font-wegiht-bold yellow--text text--darken-3">รายการทั้งหมด </label>
                            <label class="font-wegiht-bold yellow--text text--darken-3">( {{ jobsSentCancel.length }} รายการ)</label>
                            <v-data-table
                                class="grey--text"
                                style="background-color: #0E0D0C;"
                                :headers="headers"
                                :items="jobsSentCancel"
                                :items-per-page="100"
                                hide-default-footer
                                no-data-text="ไม่พบข้อมูล"
                                @click:row="clickRow"
                            >
                                <template v-slot:[`item.code`]="{ item }">
                                    <div>
                                        <label class="d-block">
                                            <v-icon>
                                                mdi-package-variant-closed
                                            </v-icon>
                                            {{ item.code }}
                                        </label>
                                    </div>
                                </template>
                                <template v-slot:[`item.details`]="{ item }">
                                    <div>
                                        <label v-for="(p, index) of item.products" :key="index" class="d-block">
                                            {{ p.details ? p.details : transformProductType(p.type) }}
                                        </label>
                                        <label class="d-block">
                                            น้ำหนัก {{ transformWeight(item.ordering.sumWeight) }}
                                        </label>
                                        <label class="d-block">
                                            ขนาด {{ transformSize(item.ordering) }} cm.
                                        </label>
                                    </div>
                                </template>
                                <template v-slot:[`item.createdAt`]="{ item }">
                                    <div>
                                        <label class="d-block">
                                            {{ transformDate(item.ordering.createdAt) }}
                                        </label>
                                    </div>
                                </template>
                                <template v-slot:[`item.status`]="{ item }">
                                    <div>
                                        <label class="d-block" :class="getColorClass(item).text">
                                            <v-icon :color="getColorClass(item).icon">
                                                mdi-circle-medium
                                            </v-icon>
                                            {{ transformStatusText(item) }}
                                        </label>
                                    </div>
                                </template>
                            </v-data-table>
                       </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            </v-tabs-items>
        </v-tabs>       
        </v-container>

        <dialog-error
            ref="dialogValidateFail"
            :topic="'ข้อมูลไม่ถูกต้อง'"
            :text="'กรุณากรอกข้อมูลใหม่อีกครั้ง'"
        />
        <dialog-error
            ref="dialogError"
            :topic="'Error'"
            :text="errorMessage"
        />
    </div>
</template>

<script>
import DialogError from '../components/DialogError.vue';
import * as Job from '../models/job';
import * as Text from '../utils/text-mapping';

export default {
    data: () => ({
        tab: 1,
        errorMessage: '',
        windowSize: {},
        headers: [ 
            { text: 'หมายเลขรายการ', value: 'code' },
            { text: 'รายละเอียด', value: 'details' },
            { text: 'วันที่ทำรายการ', value: 'createdAt' },
            { text: 'สถานะรายการ', value: 'status' } ],
        jobs: [],
        jobsCurrent: [],
        jobsSentCancel: []
    }),
    methods: {
        onResize(){
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
        transformSize(value){
            return `${value.width || ''} x ${value.length || ''} x ${value.height || ''}`;
        },
        transformWeight(weight){
            let unit = '';
            if('g' == weight.unit){
                unit = 'กรัม';
            }else if('kg' == weight.unit){
                unit = 'กิโลกรัม';
            }else if('b' == weight.unit){
                unit = 'บาท';
            }else{
                unit = '';
            }
            return `${weight.value} ${unit}`;
        },
        transformDate(dateText) {
            const date = new Date(dateText);
            return Text.thaiDate(date);
        },
        transformStatusText(job) {
            return Text.jobStatusText(job);
        },
        getColorClass(job){
            return Text.jobStatusColorText(job);
        },
        transformProductType(type){
            return Text.productTypeToText(type);
        },
        clickRow(e){
            this.$router.push({ name: 'OrderDetails', params: { id: e.id } });
        }
    },
    async mounted(){
        // get my jobs
        let loader = this.$loading.show();
        try{
            const response = await Job.getMyJobs();
            const data = response.data.jobs;
            if(data){
                this.jobs = data;
                this.jobsCurrent = this.jobs.filter(j => j.ordering.status != 'sent' && j.ordering.status != 'cancel');
                this.jobsSentCancel = this.jobs.filter(j => 'sent' == j.ordering.status || 'cancel' == j.ordering.status);
            }
        }catch(error){
            this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
            this.$refs.dialogError.show();
        }finally{
            loader.hide();
        }
    },
    components: {
        DialogError
    }
};
</script>

<style scoped>
    .div-my-order{
        text-align: center;
    }
    
</style>